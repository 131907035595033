@font-face {
  font-family: "BasisGrotesquePro";
  font-style: normal;
  font-weight: 400;
  src: url("basis-grotesque-regular-pro.woff2") format("woff2");
}

@font-face {
  font-family: "NotoSansJPRegular";
  font-style: normal;
  font-weight: 500;
  src: url("NotoSansJP-Regular.otf");
}
